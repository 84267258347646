import StravaEmbed from "./StravaEmbedded";

function Home(){
return (
<div>
    <StravaEmbed id="11331075161"/>
</div>
)

}

export default Home;